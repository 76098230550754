<template>
    <iframe
        ref="knowbot"
        tabindex="0"
        :src="`https://chat.applai.io/chatbot/${knowbot?.externalId}?waitForInit=true`"
        title="Knowbot"
        width="100%"
        height="100%"
        class="border-0"
        allowfullscreen
    />
</template>

<script lang="ts">
    import Vue, { VueConstructor, PropType } from 'vue'
    import { get } from 'vuex-pathify'

    export default (Vue as VueConstructor<Vue>).extend({
        props: {
            config: {
                type: Object as PropType<KnowbotChatConfig>,
                required: true,
            },
        },

        data() {
            return {
                targetOrigin: 'https://chat.applai.io',
            }
        },

        computed: {
            iframeElement(): HTMLIFrameElement | null {
                return this.$refs.knowbot as HTMLIFrameElement
            },

            knowbot: get<KnowbotModel | null>('Knowbot/knowbot'),
        },

        created() {
            window.addEventListener('message', this.message)
        },

        beforeDestroy() {
            window.removeEventListener('message', this.message)
        },

        methods: {
            message(event: MessageEvent): void {
                if (event.origin !== this.targetOrigin) return

                if (event.data.type === 'source-clicked') {
                    console.log('source-clicked')

                    window.open(event.data.url, '_blank')
                }

                if (event.data.type === 'close') {
                    this.$emit('close')
                }

                if (event.data.type === 'init') {
                    const iframe = this.$refs.knowbot as HTMLIFrameElement
                    iframe?.contentWindow?.postMessage(this.config, this.targetOrigin)
                }
            },
        },
    })
</script>
